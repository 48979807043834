import React from 'react';
import PropTypes from 'prop-types';


const Hello = props => (
    <div className="is-hidden">Hello {props.name}!</div>
)

Hello.propTypes = {
    name: PropTypes.string
};

export default Hello